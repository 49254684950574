<!-- 直流屏 -->
<template>
  <div id="transformer">
    <!-- <div class="tree">
      <div>变配电站</div>
      <img src="../../assets/images/monitor/screen.png" alt="" />
    </div> -->
    <div class="main">
      <div class="flex-horizontal-between">
        <div class="title">
          <img src="../../assets/images/LTicon.png" alt="" />
          <span>直流屏</span>
        </div>
        <!-- <div class="update-time">
          <span>更新时间</span>
          <p>2023-12-12</p>
        </div> -->
      </div>
      <ul class="tabs">
          <li v-for="item in tabsData" :key="item.type" :class="select == item.type ? 'select' : ''"
            @click="switchTabs(item)">
            {{ item.name }}
          </li>
        </ul>
      <div class="content">
        <el-table  class="table" :data="tableData">
          <!-- <el-table-column align="center" prop="name" label="直流屏名称">
          </el-table-column>
          <el-table-column align="center" prop="name" label="名称">
          </el-table-column> -->
          <el-table-column align="center" prop="nowTime" label="采集时间" width="175">
            <template slot-scope="scope">
              {{ scope.row.nowTime.substr(11, 8) }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="numberOneBattery" :label="`1号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberTwoBattery" :label="`2号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberThreeBattery" :label="`3号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberFourBattery" :label="`4号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberFiveBattery" :label="`5号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberSixBattery" :label="`6号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberSevenBattery" :label="`7号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberEightBattery" :label="`8号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberNineBattery" :label="`9号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberTenBattery" :label="`10号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberElevenBattery" :label="`11号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberTwelveBattery" :label="`12号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberThirteenBattery" :label="`13号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberFourteenBattery" :label="`14号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberFifteenBattery" :label="`15号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberSixteenBattery" :label="`16号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberSeventeenBattery" :label="`17号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
          <el-table-column align="center" prop="numberEighteenBattery" :label="`18号电池 ${selectName}${unit}`" width="175">
          </el-table-column>
         
       
        </el-table>
       
      </div>
    </div>
    <el-dialog title="图表" :visible.sync="chartDialog" width="1268px">
      <div id="chart"></div>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { nextTick } from "vue";
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
      },
      tabsData:[
        {
          name:'电压',
          type:1
        },
        {
          name:'温度',
          type:2,
        },
        {
          name:'内阻',
          type:3
        },
        {
          name:'SOC',
          type:4
        },
        {
          name:'SOH',
          type:5
        },


      ],
      select: 1 ,
      chartDialog: false,
      exportDialog: false,
      tableData: [],
      select: 1,
      stationNumber:'',
      loopList:[],
      selectName:'电压',
      unit:'(V)',
     
     
    };
  },
  created(){
    this.$store.commit('increment', '电力监测')
    this.$store.commit('selectChild', '直流屏')
    this.$store.commit("selectChildren", "");
  },

  mounted() {
    this.stationNumber=sessionStorage.getItem('stationNumber')
    // this.stationNumber = 'ZD0279-01'
    // emissionsCarbonApi.getLoopList({ stationNumber: this.stationNumber }).then(res => {
    //   this.loopList = res.data
    //   this.formData.deviceNumber = res.data[0].deviceNumber
    // })
    this.getScreenList()
   },

  methods: {
    switchTabs(item) {
      this.selectName=item.name
      console.log(item);
      if(item.type==1){
        this.unit='(V)'
      }else if(item.type==2){
        this.unit='(℃)'
      }else if (item.type==3){
        this.unit='(mΩ)'
      }else if(item.type==4){
        this.unit='(%)'
      }else if(item.type==5){
        this.unit='(%)'
      }else{
        this.unit=''
      }
      this.select = item.type;
      this.getScreenList()
    },
    
    getScreenList(){

      let obj={
        stationNumber:this.stationNumber,
        type1:this.select,
        startTime:'',
        endTime:'',
      }
      var date = new Date();
        date.setDate(date.getDate());
        var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        obj.startTime = yesterday
        obj.endTime = yesterday
      emissionsCarbonApi.getScreenList(obj).then(res=>{
        this.tableData=res.data
      })
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

#transformer {
  width: 100%;
  min-height: 903px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

.tree {
  width: 240px;
}

.tree div {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 38px 21px 21px;
}

.tree img {
  width: 48px;
  height: 48px;
  margin-left: 94px;
}

.main {
  width: 1376px;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

.update-time {
  display: flex;
  align-items: center;
  color: #aed6ff;
  font-size: 12px;
}

.update-time span::after {
  content: ":";
  margin: 0 3px;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}

::v-deep .el-dialog__body {
  padding: 0;
}

#chart {
  width: 1268px;
  height: 671px;
}

/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination :deep() .el-pagination {
  padding-left: 0;
}

.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}

.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}

.el-pagination>>>.el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.jump-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-input {
  height: 30px;
  width: 35px;
  color: #92b5da;
  margin: 0 10px;
}

.pagination-input>>>.el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
}

.pagination :deep().el-select .el-input__inner {
  height: 30px;

  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}

.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}

.jump-pagination .text {
  color: #92b5da;
  line-height: 30px;
}

.confirm {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0);
  background-color: #176ad2;
  font-family: "微软雅黑", sans-serif;
  color: #d7d7d7;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 5px;
}
.tabs {
  width: 1336px;
  background-color: rgb(4, 25, 66);
  display: flex;
  margin-bottom: 10px;
}

.tabs li {
  width: 122px;
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.tabs li:hover{
  background-color: #002c6c;
  color: #ffc74a;
}
.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}
::v-deep .el-table__body-wrapper {
  max-height: 75vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

</style>
